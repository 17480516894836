import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SessionContextProvider } from "@emisgroup/application-session-react";
import { ISessionContext } from "@emisgroup/application-session-management";
import { TelemetryProvider } from "@emisgroup/clint-telemetry";
import { TokenProvider, augmentTelemetryData } from "@emisgroup/patient-connect-common-react";
import { Layout } from "./layout/Index";
import { EnvironmentProvider } from "./environmentContext";
import { TelemetryContext, createValue } from "./telemetry";
import { ApiProvider } from "./context/api";
import { ServiceProvider } from "./components/serviceContext";
import { PatientDetailsProvider } from "./components/patientDetailsContext";
import { ReferralSubmissionProvider } from "./components/referralSubmissionContext";
import packageJson from "../package.json";
import { RedirectsProvider } from "./context/redirects";
import "@emisgroup/ui-styles/dist/base.css";

const WithTokenProvider = ({ userAccessToken, children }) => {
    const { trackException } = React.useContext(TelemetryContext);
    const onTokenError = (error: Error) => trackException({ exception: error });

    return (
        <TokenProvider userAccessToken={userAccessToken} onError={onTokenError}>
            {children}
        </TokenProvider>
    );
};

export interface IApplicationProps {
    isRunningInApplicationFrame: boolean;
    defaultServiceId?: string;
    sessionContext: ISessionContext;
}
/**
 * Responsible for rendering the Application component
 */
const Application = (props: IApplicationProps): JSX.Element => {
    /**
     * This section should be used to setup the application when the application
     * is navigated to in the frame.
     */
    const onApplicationMount = (): void => {
        // Setup
    };

    /**
     * This section should is used to clean up when the application
     * is navigated away from in the frame.
     */
    const onApplicationUnMount = (): void => {
        // Cleanup
    };

    useEffect(() => {
        onApplicationMount();

        return (): void => {
            onApplicationUnMount();
        };
    });

    const location = useLocation();
    const queryStringParams = new URLSearchParams(location.search);
    const serviceId = queryStringParams.get("serviceId") ?? props.defaultServiceId;
    const isPatientLed = process.env.APP_PATIENT_LED_REFERRAL === "true";

    return (
        <EnvironmentProvider
            isRunningInApplicationFrame={props.isRunningInApplicationFrame}
            isPatientLed={isPatientLed}
        >
            <SessionContextProvider value={props.sessionContext}>
                <TelemetryProvider
                    appInsightsKey={process.env.APP_INSTRUMENTATION_KEY}
                    context={TelemetryContext}
                    createContextValue={createValue}
                    enableInDev
                    applicationName="patient-connect-referrals"
                    augmentData={item => {
                        if (augmentTelemetryData(item, packageJson.version)) {
                            item.data.appMode = isPatientLed ? "PatientLed" : "PracticeLed";
                        }
                    }}
                    autoFlushExceptions
                >
                    <WithTokenProvider userAccessToken={props.sessionContext?.accessToken?.value}>
                        <ServiceProvider initialServiceId={serviceId}>
                            <ApiProvider>
                                <PatientDetailsProvider>
                                    <RedirectsProvider>
                                        <ReferralSubmissionProvider>
                                            <Layout />
                                        </ReferralSubmissionProvider>
                                    </RedirectsProvider>
                                </PatientDetailsProvider>
                            </ApiProvider>
                        </ServiceProvider>
                    </WithTokenProvider>
                </TelemetryProvider>
            </SessionContextProvider>
        </EnvironmentProvider>
    );
};

export default Application;
