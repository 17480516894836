import React from "react";
import { ApiContext } from "../../context/api";
import { TelemetryContext } from "../../telemetry";
import { CareProviderRecord } from "../types";
import { GetResult, QueryResult } from "../../api/queries";

export enum CareProviderSearchState {
    Pending = "Pending",
    Loading = "Loading",
    SearchTermInvalid = "SearchTermInvalid",
    Error = "Error",
    Success = "Success",
}

const useCareProviderSearch = (serviceId: string) => {
    const [state, setState] = React.useState(CareProviderSearchState.Pending);
    const [data, setData] = React.useState<{ careProvider: CareProviderRecord[] } | null>(null);
    const { careProviderSearch } = React.useContext(ApiContext);
    const { trackInvalidPostcode, trackSearch, trackException } = React.useContext(TelemetryContext);

    const search = async (term: string) => {
        setState(CareProviderSearchState.Loading);
        try {
            const result: QueryResult = await careProviderSearch(term, serviceId);
            if (result.getResult === GetResult.Success) {
                setData(result.data.data);
                setState(CareProviderSearchState.Success);
                trackSearch({ term, results: result.data.data });
            } else if (result.status === 400) {
                setState(CareProviderSearchState.SearchTermInvalid);
                trackInvalidPostcode({ term });
            } else {
                setState(CareProviderSearchState.Error);
                trackSearch({ term, results: [] });
            }
        } catch (e) {
            setState(CareProviderSearchState.Error);
            trackException({ exception: e });
        }
    };

    const clearSearch = () => {
        setState(CareProviderSearchState.Pending);
        setData(null);
    };

    return { search, clearSearch, state, data };
};

export default useCareProviderSearch;
