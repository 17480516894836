import * as React from "react";
import Application from "./App";
import { IntlProvider } from "./IntlProvider";
import { AddEmisWebEventHandlers, RemoveEmisWebEventHandlers } from "./events/handlers";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { TokenState, IdpType, SessionItems } from "@emisgroup/application-session-management";
import GetSessionContext from "./session/getSessionContext";
import { BrowserRouter } from "react-router-dom";
import { IPatientDetail } from "./events/types";

export const SignInPath = "/signin";

/**
 * Responsible for rendering the component as per route path
 */
export const AppLocal: React.FC = () => {
    const [sessionContext] = React.useState(GetSessionContext());

    if (Boolean(process.env.APP_DEV_TOKEN)) {
        sessionContext.UpdateAccessToken({
            idp: IdpType.Emis,
            value: process.env.APP_DEV_TOKEN,
        });
    }

    if (Boolean(process.env.APP_DEV_PATIENT_GUID)) {
        const patientDetail: IPatientDetail = {
            PatientGuid: process.env.APP_DEV_PATIENT_GUID,
        };
        sessionContext.SetItem(SessionItems.Patient, JSON.stringify(patientDetail));
    }

    const [isAuthenticated, setIsAuthenticated] = React.useState(sessionContext.accessToken.state === TokenState.Valid);

    React.useEffect(() => {
        AddEmisWebEventHandlers({
            sessionContext,
            setIsAuthenticated,
        });
        return () => {
            RemoveEmisWebEventHandlers();
        };
    }, []);

    const isAuthenticationRequired = process.env.APP_PATIENT_LED_REFERRAL !== "true";
    if (isAuthenticationRequired && !isAuthenticated)
        return <ProgressSpinnerLarge text="Waiting for authentication via Chrome Extension" />;

    return (
        <IntlProvider>
            <BrowserRouter>
                <Application isRunningInApplicationFrame={false} sessionContext={sessionContext} />
            </BrowserRouter>
        </IntlProvider>
    );
};

export default AppLocal;
