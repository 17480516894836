/* eslint-disable react/jsx-no-bind */
import { useTranslation } from "@emisgroup/application-intl";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import * as React from "react";
import PostcodeSelection from "./postcodeSelection";
import useCareProviderSearch, { CareProviderSearchState } from "./useCareProviderSearch";
import Results from "./results";
import BackLink from "../backLink";
import { SelectedCareProviderContext } from "../selectedCareProviderContext";
import { PatientDetailsContext } from "../patientDetailsContext";
import { POSTCODE_REGEX } from "../regex";
import { ServiceContext } from "../serviceContext";
import Error from "../Query/error";
import "../searches.scss";

type CareProviderSearchProps = {
    onBack: () => void;
};

function Index({ onBack }: Readonly<CareProviderSearchProps>) {
    const { t } = useTranslation();
    const { serviceId } = React.useContext(ServiceContext);
    const { patientDetails } = React.useContext(PatientDetailsContext);
    const { originPostcode, onSearch } = React.useContext(SelectedCareProviderContext);
    const [postcode, setPostcode] = React.useState<string>(originPostcode);
    const [error, setError] = React.useState<string>(undefined);
    const { search, clearSearch, state, data } = useCareProviderSearch(serviceId);

    React.useEffect(() => {
        if (postcode) {
            search(postcode);
        }
    }, []);

    React.useEffect(() => {
        if (patientDetails?.postcode && !postcode) {
            setPostcode(patientDetails.postcode);
            onSearch(patientDetails.postcode);
            search(patientDetails.postcode);
        }
    }, [patientDetails]);

    React.useEffect(() => {
        if (state === CareProviderSearchState.SearchTermInvalid) {
            setError(t("Search.InvalidPostcode"));
        }
    }, [state]);

    const handleSearch = () => {
        const canSearch = POSTCODE_REGEX.test(postcode);
        setError(canSearch ? undefined : t("Search.InvalidPostcode"));
        if (canSearch) {
            onSearch(postcode);
            search(postcode);
        } else {
            clearSearch();
        }
    };

    return (
        <>
            <BackLink onBack={onBack} />
            <PostcodeSelection
                postcode={postcode}
                onChange={setPostcode}
                onSearch={handleSearch}
                error={error}
                disabled={state === CareProviderSearchState.Loading}
            />
            {state === CareProviderSearchState.Loading && (
                <ProgressSpinner
                    data-testid="provider-search-spinner"
                    small={false}
                    horizontal={true}
                    className="provider-search-spinner"
                    text={t("Search.Searching")}
                />
            )}
            {state === CareProviderSearchState.Error && (
                <Error title={t("Search.ErrorTitle")} text={t("Search.ErrorText")} />
            )}
            {state === CareProviderSearchState.Success && <Results term={postcode} data={data} />}
        </>
    );
}

export default Index;
