import { useTranslation } from "@emisgroup/application-intl";
import { useSessionContext } from "@emisgroup/application-session-react";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import React from "react";
import { DeclineType, ReferralErrorType } from "../../api/referrals";
import { TelemetryContext } from "../../telemetry";
import { AssessmentContext } from "../assessmentContext";
import usePatientGuid from "../usePatientGuid";
import { ServiceContext } from "../serviceContext";
import { PatientDetails, CareProviderRecord, EmailAssessmentOption } from "../types";
import { ApiContext } from "../../context/api";
import { EnvironmentContext } from "../../environmentContext";
import { CareProviderIdentifierName } from "../../queries/constants";
import SubmissionResult, { ReferralResult } from "./submissionResult";
import { tryTrack } from "../utils";

type SubmitReferralProps = {
    patientDetails: PatientDetails;
    declineType?: DeclineType;
    careProvider?: CareProviderRecord;
    onComplete?: () => void;
};

const getSuccessText = (declineType?: DeclineType) => {
    if (declineType) {
        return declineType === DeclineType.Ineligible ? "Referral.UnsuitableForReferral" : "Referral.ReferralDeclined";
    }

    return "Referral.ReferralCreated";
};

const getSanitizedPatientDetails = patientDetails => ({
    ...patientDetails,
    nhsNumber: patientDetails.nhsNumber?.replace(/\s/g, ""),
});

const submitReferral = async ({
    isPatientLed,
    declineType,
    trackReferralDecline,
    trackReferralCreated,
    patientDetails,
    referralCreatedCodeId,
    referralDeclinedCodeId,
    postDeclineReferral,
    serviceId,
    serviceName,
    patientIdentifier,
    assessmentTemplateResult,
    selectedProviderId,
    selectedProviderName,
    setLoadingText,
    postReferral,
    fileToClinicalRecord,
    setReferralResult,
    successText,
    partialSuccessText,
    failureText,
    trackException,
    onComplete,
}) => {
    try {
        let referralId;

        if (declineType) {
            const declineCode = declineType === DeclineType.PatientDeclined ? referralDeclinedCodeId : "";
            referralId = await postDeclineReferral(
                serviceId,
                patientIdentifier,
                getSanitizedPatientDetails(patientDetails),
                patientDetails.referralReason,
                declineType,
                assessmentTemplateResult,
                declineCode,
            );
            if (declineType === DeclineType.PatientDeclined) {
                trackReferralDecline({ reason: patientDetails.referralReason });
            }
        } else {
            if (!selectedProviderId) throw Error("No selected service provider on referral creation");
            const sendAssessmentToSubject: boolean = patientDetails.emailAssessment === EmailAssessmentOption.Yes;
            referralId = await postReferral(
                serviceId,
                serviceName,
                patientIdentifier,
                getSanitizedPatientDetails(patientDetails),
                selectedProviderId,
                selectedProviderName,
                assessmentTemplateResult,
                sendAssessmentToSubject,
                referralCreatedCodeId,
            );
            trackReferralCreated({ patient: patientIdentifier, serviceId, referralId });
        }

        if (!referralId) throw new Error("No referral ID returned from referrals API");

        let notificationMessage = successText;
        if (!isPatientLed) {
            setLoadingText("Referral.Filing");
            if (!(await fileToClinicalRecord(referralId))) {
                notificationMessage = partialSuccessText;
            }
        }

        setReferralResult({
            declined: declineType !== undefined,
            notificationMessage,
            succeeded: true,
        });
    } catch (exception) {
        trackException({ exception });
        setReferralResult({
            declined: declineType !== undefined,
            notificationMessage: failureText,
            succeeded: false,
        });
    }
    if (onComplete) onComplete();
};

const SubmitReferral = ({ careProvider, patientDetails, declineType, onComplete }: SubmitReferralProps) => {
    const { t } = useTranslation();
    const [referralResult, setReferralResult] = React.useState<ReferralResult>();
    const { isPatientLed } = React.useContext(EnvironmentContext);
    const { serviceId, serviceName, sendMode, referralCreatedCodeId, referralDeclinedCodeId, referralErrorMessage } =
        React.useContext(ServiceContext);
    const { assessmentTemplateResult } = React.useContext(AssessmentContext);
    const { trackException, trackReferralDecline, trackReferralCreated } = React.useContext(TelemetryContext);
    const { postReferral, postDeclineReferral, postReferralError, postClinicalRecord } = React.useContext(ApiContext);
    const patientIdentifier = usePatientGuid();
    const sessionContext = useSessionContext();
    const [loadingText, setLoadingText] = React.useState(() => {
        if (declineType) {
            return declineType === DeclineType.Ineligible ? "Referral.UnsuitableForReferral" : "Referral.Declining";
        }

        return "Referral.Creating";
    });

    const fileToClinicalRecord = async (referralId: string) => {
        const success = await tryTrack(
            () => postClinicalRecord(referralId, sessionContext.accessToken.value, declineType),
            trackException,
        );
        if (!success) {
            // try post the error but don't report to user if it fails
            await tryTrack(() => postReferralError(referralId, ReferralErrorType.ConsultationFiling), trackException);
        }
        return success;
    };

    React.useEffect(() => {
        const successText = t(getSuccessText(declineType));
        const partialSuccessText = t("Referral.ReferralCreatedButFailedToUpdateClinicalRecord");
        const failureText = t("Referral.FailedToRecordReferralOutcome");
        const selectedProviderId = careProvider?.identifier.find(i => i.name === CareProviderIdentifierName)?.value;

        submitReferral({
            isPatientLed,
            declineType,
            trackReferralDecline,
            trackReferralCreated,
            patientDetails,
            referralCreatedCodeId,
            referralDeclinedCodeId,
            postDeclineReferral,
            serviceId,
            serviceName,
            patientIdentifier,
            assessmentTemplateResult,
            selectedProviderId,
            selectedProviderName: careProvider?.name,
            setLoadingText,
            postReferral,
            fileToClinicalRecord,
            setReferralResult,
            successText,
            partialSuccessText,
            failureText,
            trackException,
            onComplete,
        });
    }, []);

    return referralResult ? (
        <SubmissionResult
            referralResult={referralResult}
            serviceName={serviceName}
            careProvider={careProvider}
            referralErrorMessage={referralErrorMessage}
            isPatientLed={isPatientLed}
            sendMode={sendMode}
        />
    ) : (
        <ProgressSpinnerLarge data-testid="submit-referral" text={t(loadingText)} lightbox />
    );
};

export default SubmitReferral;
